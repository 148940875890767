import { isAxiosError } from "axios";

import { getErrorFromAxiosError } from "@/api/app/error";

interface ErrorInfo {
  cause: unknown;
}

export class UnknownError extends Error {
  constructor(errorInfo: ErrorInfo) {
    super("An unknown error occurred", { cause: errorInfo.cause });
    this.name = "UnknownError";
  }
}

/**
 * Logs any error to the console.
 * If the error is an Axios error, it logs the error message extracted from the Axios error.
 * Otherwise, it logs the error object itself.
 *
 * @param error - The error to be logged.
 */
export const logUnknownError = (error: unknown): void => {
  if (isAxiosError(error)) {
    console.error(getErrorFromAxiosError(error));
    return;
  }

  console.error(error);
};
