export interface CommunityConfig {
  url: string;
}

export interface InternationalizationConfig {
  locale: Intl.LocalesArgument;
  currencyCode: string;
  dateFormat: string;
}

export interface SeoConfig {
  keywords: string[];
}

export interface UiConfig {
  snackbar: {
    autoHideDuration: number;
  };
}

export interface ApplicationConfiguration {
  appName: string;
  poweredBy: { name: string; href: string }[];
  mail: {
    support: string;
  };
  intl: InternationalizationConfig;

  seo: SeoConfig;

  community: Record<string, CommunityConfig>;
}

export const config: ApplicationConfiguration = {
  appName: "CipherNode",

  poweredBy: [
    {
      name: "Frolearn Private Limited",
      href: "https://www.frolearn.com/",
    },
  ],

  mail: {
    support: "support@ciphernode.ai",
  },

  intl: {
    locale: "en-IN",
    currencyCode: "INR",
    dateFormat: "DD MMM YYYY",
  },

  seo: {
    keywords: ["ciphernode", "ciphernode ai", "ciphernode services", "moi", "ethereum", "solana", "avalanche", "validator", "blockchain"],
  },

  community: {
    facebook: {
      url: "https://www.facebook.com/ciphernodeai",
    },
    linkedin: {
      url: "https://www.linkedin.com/company/ciphernode/",
    },
    twitter: {
      url: "https://twitter.com/cipher_node",
    },
    whatsapp: {
      url: "http://wa.me/+917204330348",
    },
    discord: {
      url: "https://discord.com/invite/sxmKCf53",
    },
    telegram: {
      url: "https://t.me/ciphernodeofficial",
    },
  },
};

export const page = {
  node_setup: {
    initialNodesCount: 3,
  },

  userProfile: {
    title: `${config.appName} | User Profile`,
    pageTitle: "User Profile",
  },

  billingAndPayments: {
    title: `${config.appName} | Billing and Payments`,
    description: "Billing and Payments page.",
    pageTitle: "Billing and Payments",
  },

  userNetwork: {
    pageTitle: "Network Details",
  },

  nodeActivity: {
    title: `${config.appName} | Node Activity`,
    pageTitle: "Node Activity",
    loadingText: "We are collecting your node activity data. Please wait a moment.",
  },
};
