import numeral from "numeral";

import { config } from "@/app/config";

import type { Currency } from "./currency";

export type HumanizeNumberFormat = "0.00 a";

/**
 * Returns the initials of a given name.
 * @param name - The name to extract initials from.
 * @returns The initials of the name.
 *
 * ```typescript
 * const initials = getNameInitials("John Doe");
 * console.log(initials); // Output: JD
 *
 * const initials = getNameInitials("Harsh Rastogi");
 * console.log(initials); // Output: HR
 * ```
 */
export const getNameInitials = (name: string): string =>
  name
    .split(" ")
    .slice(0, 2)
    .map((part) => part.charAt(0))
    .join("")
    .toUpperCase();

/**
 * Formats a number to a currency string.
 *
 * @param amount - The number to format.
 * @param currency - The currency code to use for formatting. If not provided, the default currency code will be used.
 * @returns The formatted currency string.
 */
export const formatToCurrency = (amount: number, currency?: Currency): string =>
  new Intl.NumberFormat(config.intl.locale, {
    style: "currency",
    currency: currency ?? config.intl.currencyCode,
  }).format(amount);

export function humanizeNumber(num: number, format: HumanizeNumberFormat): string;
export function humanizeNumber(num: number, format: string): string;
export function humanizeNumber(num: number, format: string): string {
  return numeral(num).format(format);
}
