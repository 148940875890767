import { ENVIRONMENT } from "@/constants";

export enum Environment {
  Development = "development",
  Production = "production",
  Local = "local",
}

/**
 * Retrieves the current environment based on the value of the NEXT_PUBLIC_ENVIRONMENT environment variable.
 * @returns The current environment.
 * @throws {Error} If the environment is invalid.
 */
export const getEnvironment = (): Environment => {
  if (ENVIRONMENT == null) {
    throw new Error("Environment not set");
  }

  if (ENVIRONMENT === "local" || ENVIRONMENT === "development" || ENVIRONMENT === "production") {
    return ENVIRONMENT as Environment;
  }

  throw new Error(`Invalid environment: ${ENVIRONMENT}`);
};
