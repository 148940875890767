import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import type { UnixSeconds } from "@/types/utils";

dayjs.extend(duration);

/**
 * Calculates the number of days between a given Unix timestamp and the current date.
 *
 * @param since - The Unix timestamp to calculate the number of days from.
 * @returns The number of days between the given timestamp and the current date.
 */
export const getDaysTillNow = (since: UnixSeconds) => dayjs().diff(dayjs.unix(since), "days");

/**
 * Returns a formatted duration string in the format "Xh, Ym" for the given duration in seconds.
 *
 * @param durationInSec - The duration in seconds.
 * @returns The formatted duration string.
 */
export const getFormattedDuration = (durationInSec: number) => {
  const timeSpan = dayjs.duration(durationInSec, "seconds");
  return `${Math.floor(timeSpan.asHours())}h, ${timeSpan.minutes()}m`;
};
