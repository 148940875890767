import currencies from "./currency-symbol-map.json";

export type Currency = keyof typeof currencies;

/**
 * Converts the given amount in paisa to rupees.
 * @param paisa The amount in paisa to be converted.
 * @returns The equivalent amount in rupees.
 */
export const fromPaisaToRupee = (paisa: number): number => paisa / 100;

/**
 * Retrieves the currency symbol for the given currency.
 * @param currency - The currency for which to retrieve the symbol.
 * @returns The currency symbol as a string.
 */
export const getCurrencySymbol = (currency: Currency): string => currencies[currency];
