import type { DateString, IRequestOptions } from "@/types/utils";

import { client } from "../client";
import type { UserNetworkApiResponse } from "../node/api-response-type";
import type {
  GetLatestNodesResponseData,
  GetNetworksResponse,
  GuardianNodesResponse,
  NetworkNodePlanResponseData,
  NodeTierResponseData,
  PendingActionsResponse,
  PendingDeploymentNodeResponse,
  SubscriptionV3,
  UserBillingSubscriptionResponse,
  ValidateUPIResponse,
} from "./api-response-types";
import type {
  GetUptimeOptions,
  GuardianNode,
  Network,
  NetworkMetadata,
  NetworkNode,
  NetworkNodePlan,
  NetworkPendingAction,
  NodeHeartbeat,
  NodeSetupCost,
  NodeTierData,
  PaymentStatus,
  PendingActionsByNodeIdResponse,
  PendingDeployment,
  SubscriptionDataWithUserData,
  UserBillingSubscription,
} from "./api-types";
import { MoiValidatorSteps } from "./api-types";
import {
  mapGuardianNodes,
  mapLatestNodes,
  mapNetworkNodePlan,
  mapNodeTiers,
  mapPendingActionNodes,
  mapPendingDeployment,
  mapToNetwork,
  mapUserSubscription,
} from "./mapping";

/**
 * Retrieves all networks.
 * @returns A promise that resolves to an array of Network objects
 */
export const getAllNetworks = async (option?: IRequestOptions): Promise<Network[]> => {
  const endpoint = "/network";
  const response = await client.get<GetNetworksResponse>(endpoint, option?.requestConfig);
  return response.data.map(mapToNetwork);
};

/**
 * Retrieves network information for a given ID.
 * @param id - The ID of the network.
 * @returns A Promise that resolves to the network information if found, or null otherwise.
 */
export const getNetwork = async (id: string, options?: IRequestOptions): Promise<Network | null> => {
  const networks = await getAllNetworks(options);
  return networks.find((network) => network.id === id) ?? null;
};

/**
 * Retrieves the latest nodes from the network.
 * @param option - Optional request options.
 * @returns A Promise that resolves to an array of latest nodes.
 */
export const getLatestNodes = async (option?: IRequestOptions) => {
  const endpoint = "network/nodes/latest";
  const response = await client.get<GetLatestNodesResponseData>(endpoint, option?.requestConfig);
  return response.data.map(mapLatestNodes);
};

/**
 * Retrieves the network nodes for a given network.
 * @param network The network object.
 * @param options Optional request options.
 * @returns A promise that resolves to an array of network nodes.
 */
export const getNetworkNodesPlan = async (network: Network, options?: IRequestOptions): Promise<NetworkNodePlan[]> => {
  const endpoint = `network/${network.id}/nodes`;
  const response = await client.get<NetworkNodePlanResponseData[]>(endpoint, options?.requestConfig);
  return response.data.map(mapNetworkNodePlan);
};

/**
 * Retrieves the setup cost for a node based on the count and node ID.
 * @param count - The number of nodes.
 * @param nodeId - The ID of the node.
 * @returns A Promise that resolves to the NodeSetupCost object containing the setup cost information.
 */
export const getNodeSetupCost = async (count: number, nodeId: string, config?: IRequestOptions): Promise<NodeSetupCost> => {
  const endpoint = `/network/node/${nodeId}/tier-by-count/${count}`;
  const response = await client.get<NodeTierResponseData["data"][number]>(endpoint, config?.requestConfig);
  const nodeTier = mapNodeTiers(response.data);

  return {
    count,
    pricing: {
      total: { amount: nodeTier.price },
      perNode: { amount: nodeTier.price / nodeTier.nodeCount },
    },
  };
};

/**
 * Retrieves the pending actions for the network.
 *
 * @param options - Optional request options.
 * @returns A promise that resolves to the pending actions data.
 */
export const getNetworkPendingActions = async (options?: IRequestOptions): Promise<NetworkPendingAction[]> => {
  const endpoint = "/network/pending-actions";
  const response = await client.get<PendingActionsResponse[]>(endpoint, options?.requestConfig);

  const actions = response.data.map(mapPendingActionNodes);

  return actions.filter((action) => action.steps.pending > 0);
};

/**
 * Retrieves the network setup configuration for a given network ID.
 * @param nodeID - The ID of the network.
 * @returns The network setup configuration object, or null if not found.
 */
export const getNodeTiers = async (nodeID: string, options?: IRequestOptions): Promise<NodeTierData | null> => {
  const endpoint = `/network/node/${nodeID}/tiers`;
  const response = await client.get<NodeTierResponseData>(endpoint, options?.requestConfig);
  const { meta } = response.data;

  return {
    meta: { maxAllowed: meta.maxCount, minAllowed: meta.minCount },
    description: response.data.description,
    tiers: response.data.data.map(mapNodeTiers),
  };
};

/**
 * Retrieves the node tier data based on the given node ID and price.
 * @param nodeID - The ID of the node.
 * @param price - The price value.
 * @param options - Optional request options.
 * @returns A promise that resolves to the NodeTierData or null.
 */
export const getNodeTierByPrice = async (nodeID: string, price: number, options?: IRequestOptions): Promise<NodeTierData | null> => {
  const endpoint = `/network/node/${nodeID}/tiers`;
  const response = await client.get<NodeTierResponseData>(endpoint, {
    params: { price },
    ...options?.requestConfig,
  });

  const { meta } = response.data;

  return {
    meta: { maxAllowed: meta.maxCount, minAllowed: meta.minCount },
    description: response.data.description,
    tiers: response.data.data.map(mapNodeTiers),
  };
};

/**
 * Retrieves the uptime of a network node.
 *
 * @param node - The network node for which to retrieve the uptime.
 * @param option - The options for retrieving the uptime.
 * @returns A Promise that resolves to the uptime of the node, or null if the uptime cannot be retrieved or the node is not found.
 */
export const getNodeHeartbeat = async (node: NetworkNode["nodeID"], option?: GetUptimeOptions): Promise<NodeHeartbeat | null> => {
  const endpoint = `/node/${node}/heartbeat`;
  const response = await client.get<NodeHeartbeat>(endpoint, { params: { unix_from: option?.since } });

  return response.data;
};

/**
 * Subscribes to a node by tier ID.
 *
 * @param tierId - The ID of the tier.
 * @param amount - The amount for the subscription.
 * @param callbackUrl - The URL to receive the callback after the subscription is initiated.
 * @returns A Promise that resolves to an object containing payment details.
 */
export const subscribeToNodeByTierId = async (tierId: string, amount: number, callbackUrl: string) => {
  const endpoint = `v3/payment/initiate-payment`;
  const response = await client.post<{ pgurl: string; success: boolean; transactionId: string }>(endpoint, { amount, tierId, callbackUrl });

  return response.data;
};

export const getSubscriptionStatus = async (transactionId: string, options?: IRequestOptions) => {
  const endpoint = `v3/payment/status/${transactionId}`;

  const response = await client.get<
    {
      _id: string;
      userId: string;
      tierId: string;
      nodeId: string;
      amount: number;
      protocolOnboardingComplete: false;
      createdAt: DateString;
      updatedAt: DateString;
      endDate: DateString;
    } & (
      | {
          state: PaymentStatus.PAYMENT_SUCCESS;
          transactionData: Record<string, unknown>;
        }
      | {
          state: Exclude<PaymentStatus, PaymentStatus.PAYMENT_SUCCESS>;
        }
    )
  >(endpoint, options?.requestConfig);

  return response.data;
};

/**
 * Retrieves the pending actions for a specific node.
 *
 * @param nodeId - The ID of the node.
 * @param option - Optional request options.
 * @returns A promise that resolves to the pending actions data.
 */
export const getPendingActionsByNodeId = async (nodeId: string, option?: IRequestOptions) => {
  const endpoint = `/network/node/${nodeId}/pending-actions`;
  const response = await client.get<PendingActionsByNodeIdResponse>(endpoint, option?.requestConfig);

  return response.data;
};

/**
 * Retrieves the network statistics for the user.
 * @returns A promise that resolves to an object containing the total number of nodes and total rewards.
 */
export const getSingleNetworkStatistics = async (networkId: string, options?: IRequestOptions) => {
  const endpoint = `/node/network/${networkId}/statistics`;

  const response = await client.get<UserNetworkApiResponse>(endpoint, options?.requestConfig);
  return response.data;
};
/**
 * Retrieves the billing subscription details for the user.
 * @param option - Optional request options.
 * @returns A promise that resolves to the user's billing subscription or null if not found.
 */
export const getUserBillingSubscription = async (option?: IRequestOptions): Promise<UserBillingSubscription | null> => {
  const endpoint = "v3/payment/subscriptions";
  const response = await client.get<UserBillingSubscriptionResponse[]>(endpoint, option?.requestConfig);

  if (response.data.length === 0) {
    return null;
  }

  const activeSubscription = response.data.find((subscription) => subscription.state === "PAYMENT_SUCCESS");

  if (activeSubscription == null) {
    return null;
  }

  return mapUserSubscription(activeSubscription);
};

/**
 * Retrieves the subscriptions associated with a given node ID.
 *
 * @param nodeId - The ID of the node.
 * @param option - Optional request options.
 * @returns A promise that resolves to an array of SubscriptionV3 objects.
 */
export const getSubscriptionByNodeId = async (nodeId: string, option?: IRequestOptions): Promise<SubscriptionV3[]> => {
  const endpoint = "v3/payment/subscriptions";
  const response = await client.get<SubscriptionV3[]>(endpoint, { ...option?.requestConfig, params: { nodeId } });

  return response.data;
};

/**
 * Retrieves the billing subscriptions for the user.
 * @param option - Optional request options.
 * @returns A promise that resolves to an array of UserBillingSubscription objects.
 */
export const getUserBillingSubscriptions = async (option?: IRequestOptions): Promise<UserBillingSubscription[]> => {
  try {
    const endpoint = "v3/payment/subscriptions";
    const response = await client.get<UserBillingSubscriptionResponse[]>(endpoint, option?.requestConfig);

    return response.data.map(mapUserSubscription);
  } catch (error) {
    return [];
  }
};

/**
 * Validates a UPI (Unified Payments Interface) ID.
 * @param upi The UPI ID to validate.
 * @returns A Promise that resolves to the response data containing the validation result.
 */
export const validateUPI = async (upi: string) => {
  const endpoint = `/payment/validate-upi/${upi}`;
  const response = await client.get<ValidateUPIResponse>(endpoint);

  return response.data;
};

/**
 * Retrieves a list of pending deployments.
 *
 * @param options - Optional request options.
 * @returns A promise that resolves to an array of `PendingDeployment` objects.
 */
export const getPendingDeployments = async (options?: IRequestOptions): Promise<PendingDeployment[]> => {
  const endpoint = `/node/pending-nodes`;
  const response = await client.get<PendingDeploymentNodeResponse[]>(endpoint, options?.requestConfig);
  return response.data.map(mapPendingDeployment);
};

/**
 * Retrieves the MOI nodes from the API.
 * @param moiID - The ID of the MOI.
 * @returns A Promise that resolves to an array of GuardianNode objects.
 */
export const getMOINodes = async (moiID: string): Promise<GuardianNode[]> => {
  const hexPrefixMoiID = moiID.startsWith("0x") ? moiID : `0x${moiID}`;
  const endpoint = `/moi-validator/user/${hexPrefixMoiID}/guardians`;
  const response = await client.get<GuardianNodesResponse[]>(endpoint);

  return response.data.map(mapGuardianNodes);
};

/**
 * Saves the selected nodes to the server.
 *
 * @param nodes - An array of GuardianNode objects representing the selected nodes.
 * @returns A Promise that resolves when the nodes are successfully saved.
 */
export const saveSelectedNodes = async (nodes: GuardianNode[]) => {
  const endpoint = `/moi-validator/${MoiValidatorSteps.SELECT_NODES}`;

  await client.post(endpoint, nodes);
};

/**
 * Uploads a node keystore to the server.
 *
 * @param node - The GuardianNode object representing the node.
 * @param keystore - The keystore object to be uploaded.
 * @param password - The password for the keystore.
 * @returns A Promise that resolves when the keystore is uploaded successfully.
 */
export const uploadNodeKeyStore = async <T extends {}>(nodeID: string, node: GuardianNode, keystore: T, password: string) => {
  const data = { keystore: JSON.stringify(keystore), password, nodeID, ...node };
  const endpoint = `/moi-validator/UPLOAD_KEYSTORES`;

  await client.post(endpoint, data);
};

export const getSubscriptiobBySubscriptionId = async (subscriptionId: string) => {
  const endpoint = `/v3/payment/subscriptions/${subscriptionId}`;
  const response = await client.get<SubscriptionDataWithUserData>(endpoint);
  return response.data;
};

/**
 * Retrieves network information from the server.
 * @param option - The request options.
 * @returns A promise that resolves to the network metadata.
 */
export const getNetworkInfo = async (option?: IRequestOptions): Promise<NetworkMetadata> => {
  const endpoint = "/moi-validator/info";
  const response = await client.get<NetworkMetadata>(endpoint, option?.requestConfig);
  return response.data;
};

export const checkIfUserHasActiveNodes = async (option?: IRequestOptions) => {
  const response = await client.get<boolean>("/node/has-nodes", option?.requestConfig);

  return response.data;
};
