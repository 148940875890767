import axios from "axios";

import { APP_SERVER_BASE_URL } from "@/constants";

/**
 * Axios HTTP client instance for making API requests to application server.
 */
export const client = axios.create({
  baseURL: APP_SERVER_BASE_URL,
  withCredentials: true,
});
