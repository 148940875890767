import type { DateString, UnixSeconds } from "@/types/utils";

import type { DeploymentStatus } from "./api-response-types";

export interface NetworkBenefit {
  title: string;
  description: string;
  imageUrl: string;
}

export interface Network {
  id: string;
  name: string;
  description: string;
  summary: string;
  logoHref: string;
  website: string;
  symbol: string;
  createdAt: Date;
  updatedAt: Date;
  benefits: NetworkBenefit[];
}

export enum PaymentStatus {
  PAYMENT_INITIATED = "PAYMENT_INITIATED",
  PAYMENT_SUCCESS = "PAYMENT_SUCCESS",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  PAYMENT_PENDING = "PAYMENT_PENDING",
  PAYMENT_DECLINED = "PAYMENT_DECLINED",
  TIMED_OUT = "TIMED_OUT",
  BAD_REQUEST = "BAD_REQUEST",
  AUTHORIZATION_FAILED = "AUTHORIZATION_FAILED",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  TRANSACTION_NOT_FOUND = "TRANSACTION_NOT_FOUND",
}

export interface NetworkNodePlan {
  nodeID: string;
  network: Pick<Network, "id">;
  type: string;
  subType: string;
  payoutFrequency: string;
  minRequirements: string;
  startPrice: number;
  createdAt: Date;
  updatedAt: Date;
  disabled: boolean;
  nodesHosted?: number;
}

export interface NodeTier {
  tierId: string;
  node: { id: NetworkNodePlan["nodeID"] };
  price: number;
  nodeCount: number;
  duration: number;
  active: boolean;
}

export interface NodeTierMeta {
  minAllowed: number;
  maxAllowed: number;
}

export interface NodeTierData {
  tiers: NodeTier[];
  description: string;
  meta: NodeTierMeta;
}

export interface NodeSetupCost {
  count: number;
  pricing: {
    total: {
      amount: number;
      currency?: string;
    };
    perNode: {
      amount: number;
      currency?: string;
    };
  };
}

export interface NetworkStats {
  totalNodes: number;
  totalRewards: string;
}

export type NetworkNodeStatus = "active" | "inactive";

export interface NetworkNode {
  networkID: string;
  ownerID: string;
  nodeID: string;
  address: string;
  runningSince: UnixSeconds;
  staked: number;
  reward: number;
  status: NetworkNodeStatus;
}

export interface LatestNetworkNode {
  nodeID: NetworkNode["nodeID"];
  type: string;
  network: Pick<Network, "id" | "name" | "logoHref">;
}

export interface GetUptimeOptions {
  since: UnixSeconds;
}

export interface NodeHealth {
  value: number;
  status: boolean;
}

export interface NodeHeartbeatLog {
  timestamp: UnixSeconds;
  stat: NodeHeartbeatStat;
  heartbeat: NodeHealth[];
}

/**
 * Represents the heartbeat status of a node.
 * such that net of `totalUptime` and `totalDowntime` is equal to 1.
 */
export interface NodeHeartbeatStat {
  totalUptime: number;
  totalDowntime: number;
}

export interface NodeHeartbeat {
  since: UnixSeconds;
  stat: {
    totalUptime: number;
    totalDowntime: number;
  };
  heartbeatLogs: NodeHeartbeatLog[];
}

export type PendingActionsByNodeIdResponse<T = unknown> = {
  _id: string;
  nodeId: string;
  nodeName: string;
  userId: string;
  stepName: MoiValidatorSteps;
  completed: boolean;
  createdAt: DateString;
  updatedAt: DateString;
  userData: T;
  otherData: unknown;
}[];

export enum MoiValidatorSteps {
  CREATE_IOME_ACCOUNT = "CREATE_IOME_ACCOUNT",
  GET_WAITLISTED = "GET_WAITLISTED",
  AWAIT_APPROVAL = "AWAIT_APPROVAL",
  GET_REGISTERED = "GET_REGISTERED",
  SELECT_NODES = "SELECT_NODES",
  ADD_PHONE_NUMBER = "ADD_PHONE_NUMBER",
  UPLOAD_KEYSTORES = "UPLOAD_KEYSTORES",
}

export interface NetworkPendingAction {
  steps: {
    completed: number;
    pending: number;
    total: number;
  };
  lastUpdated: Date;
  node: Pick<NetworkNodePlan, "nodeID" | "type">;
  network: Pick<Network, "id" | "name">;
}

interface SubscriptionPayment {
  subscriptionID: string;
  amount: number;
  state: string;
  validUpto: Date;
}

export interface UserBillingSubscription {
  id: string;
  userID: string;
  tier: NodeTier & {
    id: string;
    node: {
      id: NetworkNode["nodeID"];
      network: {
        id: Network["id"];
        name: Network["name"];
      };
    };
  };
  payment: SubscriptionPayment;
  updatedAt: Date;
  createdAt: Date;
}

export interface PendingDeployment {
  id: string;
  userID: string;
  network: Pick<Network, "id" | "name">;
  node: Pick<NetworkNodePlan, "nodeID" | "type"> & { address: string };
  status: DeploymentStatus;
  createdAt: Date;
  updatedAt: Date;
}

export interface GuardianNode {
  nodeIndex: number;
  kramaID: string;
  status: number;
  registrationStatus: number;
}

export interface SubscriptionDataWithUserData {
  _id: string;
  userId: {
    _id: string;
    name: string;
  };

  tierId: {
    _id: string;
    nodeId: {
      _id: string;
      type: string;
      networkId: {
        _id: string;
        displayName: string;
      };
    };
    price: number;
    nodeCount: number;
    duration: number;
    active: true;
    createdAt: DateString;
    updatedAt: DateString;
    __v: 0;
  };

  nodeId: string;
  amount: number;
  state: "PAYMENT_SUCCESS";
  protocolOnboardingComplete: boolean;
  createdAt: DateString;
  updatedAt: DateString;
  __v: 0;
  endDate: DateString;
  transactionData: Record<string, unknown>;
}

export interface NetworkMetadata {
  nodes: number;
  rewards: number;
}
