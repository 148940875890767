import numeral from "numeral";

/**
 * Represents a unit of storage.
 * Possible values are "KB" (Kilobyte), "MB" (Megabyte), "GB" (Gigabyte), "TB" (Terabyte), "Bit", and "Byte".
 */
export type StorageUnit = "KB" | "MB" | "GB" | "TB" | "Bit" | "Byte";

export type StorageFormat = "0.0 b" | "0.0b";

/**
 * Represents a memory storage with conversion methods for different storage units.
 */
export class MemoryStorage {
  private readonly amount: number;

  /**
   * Creates a new instance of the MemoryStorage class.
   * @param amount The amount of storage.
   * @param unit The unit of storage (e.g., "KB", "MB", "GB", "TB", "Bit", "Byte").
   * @throws Error if an invalid unit is provided.
   */
  constructor(amount: number, unit: StorageUnit) {
    switch (unit) {
      case "KB":
        this.amount = amount * 1024;
        break;
      case "MB":
        this.amount = amount * 1024 * 1024;
        break;
      case "GB":
        this.amount = amount * 1024 * 1024 * 1024;
        break;
      case "TB":
        this.amount = amount * 1024 * 1024 * 1024 * 1024;
        break;
      case "Bit":
        this.amount = amount / 8;
        break;
      case "Byte":
        this.amount = amount;
        break;
      default:
        throw new Error("Invalid unit");
    }
  }

  convert(unit: StorageUnit) {
    switch (unit) {
      case "KB":
        return this.amount / 1024;
      case "MB":
        return this.amount / 1024 / 1024;
      case "GB":
        return this.amount / 1024 / 1024 / 1024;
      case "TB":
        return this.amount / 1024 / 1024 / 1024 / 1024;
      case "Bit":
        return this.amount * 8;
      case "Byte":
        return this.amount;
      default:
        throw new Error("Invalid unit");
    }
  }

  toString(unit: StorageUnit): string {
    return `${this.convert(unit).toFixed(1)} ${unit}`;
  }

  toGB(): number {
    return this.amount / 1024 / 1024 / 1024;
  }

  static humanize(amount: number, unit: StorageUnit, format: StorageFormat): string {
    return numeral(new MemoryStorage(amount, unit).convert("Byte")).format(format);
  }
}
