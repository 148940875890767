import { fromPaisaToRupee } from "@/lib/util";

import type {
  GuardianNodesResponse,
  NetworkNodePlanResponseData,
  NetworkNodeResponseData,
  NetworkResponseData,
  NodeTierResponseData,
  PendingActionsResponse,
  PendingDeploymentNodeResponse,
  UserBillingSubscriptionResponse,
} from "./api-response-types";
import type { GuardianNode, LatestNetworkNode, Network, NetworkNodePlan, NetworkPendingAction, NodeTier, UserBillingSubscription } from "./api-types";

/**
 * Maps the network response data to a Network object.
 *
 * @param network - The network response data.
 * @returns The mapped Network object.
 */
export const mapToNetwork = (network: NetworkResponseData): Network => ({
  id: network._id,
  name: network.displayName,
  description: network.description,
  summary: network.reqText,
  logoHref: network.imageUrl,
  website: network.websiteLink,
  benefits: network.benefits,
  symbol: network.shortName,
  createdAt: new Date(network.createdAt),
  updatedAt: new Date(network.updatedAt),
});

/**
 * Maps the response data from the network API to the LatestNetworkNode type.
 *
 * @param data - The response data from the network API.
 * @returns The mapped LatestNetworkNode object.
 */
export const mapLatestNodes = (data: NetworkNodeResponseData): LatestNetworkNode => ({
  nodeID: data._id,
  type: data.type,
  network: {
    id: data.networkId._id,
    name: data.networkId.displayName,
    logoHref: data.networkId.imageUrl,
  },
});

/**
 * Maps a NetworkNodePlan object to a simplified format.
 *
 * @param data - The NetworkNodePlan object to be mapped.
 * @returns The mapped object with simplified properties.
 */
export const mapNetworkNodePlan = (data: NetworkNodePlanResponseData): NetworkNodePlan => ({
  nodeID: data._id,
  network: {
    id: data.networkId,
  },
  type: data.type,
  subType: data.subType,
  payoutFrequency: data.payOutFrequency,
  minRequirements: data.minRequirements,
  startPrice: data.startPrice,
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
  disabled: false,
  nodesHosted: data.nodesHosted,
});

export const mapNodeTiers = (data: NodeTierResponseData["data"][number]): NodeTier => ({
  tierId: data._id,
  node: { id: data.nodeId },
  active: data.active,
  duration: data.duration,
  nodeCount: data.nodeCount,
  price: data.price,
});

export const mapUserSubscription = (data: UserBillingSubscriptionResponse): UserBillingSubscription => ({
  id: data._id,
  createdAt: new Date(data.createdAt),
  updatedAt: new Date(data.updatedAt),
  userID: data.userId,
  tier: {
    active: data.tierId.active,
    duration: data.tierId.duration,
    nodeCount: data.tierId.nodeCount,
    price: fromPaisaToRupee(data.tierId.price),
    tierId: data.tierId._id,
    id: data.tierId._id,
    node: {
      id: data.tierId.nodeId._id,
      network: {
        id: data.tierId.nodeId.networkId._id,
        name: data.tierId.nodeId.networkId.displayName,
      },
    },
  },
  payment: {
    amount: fromPaisaToRupee(data.amount),
    state: data.state,
    subscriptionID: data._id,
    validUpto: new Date(data.endDate),
  },
});

export const mapPendingDeployment = (deployment: PendingDeploymentNodeResponse) => ({
  id: deployment._id,
  userID: deployment.userId,
  network: { id: deployment.networkId._id, name: deployment.networkId.displayName },
  node: { nodeID: deployment.nodeId._id, type: deployment.nodeId.type, address: deployment.nodeAddress },
  status: deployment.status,
  createdAt: new Date(deployment.createdAt),
  updatedAt: new Date(deployment.updatedAt),
});

export const mapGuardianNodes = (data: GuardianNodesResponse): GuardianNode => ({
  nodeIndex: data.node_index,
  kramaID: data.krama_id,
  status: data.status,
  registrationStatus: data.registration_status,
});

export const mapPendingActionNodes = (data: PendingActionsResponse): NetworkPendingAction => ({
  steps: {
    completed: data.stepsCompleted,
    pending: data.stepsPending,
    total: data.stepsCompleted + data.stepsPending,
  },
  lastUpdated: new Date(data.lastUpdated),
  node: { nodeID: data.nodeId, type: data.nodeType },
  network: { id: data.network._id, name: data.network.displayName },
});
