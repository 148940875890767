export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME;

export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT;

export const CURRENT_FULL_YEAR = new Date().getFullYear();

export const GOOGLE_AUTH_REDIRECT_URL = process.env.NEXT_PUBLIC_GOOGLE_OAUTH_REDIRECT_URL;

export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;

export const APP_SERVER_BASE_URL = process.env.NEXT_PUBLIC_APP_SERVER_BASE_URL;

export const JWT_TOKEN_COOKIE_NAME = process.env.NEXT_PUBLIC_JWT_TOKEN_COOKIE_NAME;

export const APP_BASE_PATH = "/";

export const IOME_APP_HREF = process.env.NEXT_PUBLIC_IOME_APP_HREF!;

export const INITIALIZE_RECURRING_DATE = 4;

export const RECURRING_AUTODEBIT_DATE = INITIALIZE_RECURRING_DATE + 1;
