import { isNode } from "browser-or-node";
import { decode, verify } from "jsonwebtoken";

import type { User } from "@/api/app";
import { GOOGLE_AUTH_REDIRECT_URL, GOOGLE_CLIENT_ID } from "@/constants";
import { UserRole } from "@/types/application";

import { Environment, getEnvironment } from "./environment";

const ROOT_URL = "https://accounts.google.com/o/oauth2/v2/auth";

/**
 * Generates the Google OAuth URL with the specified options.
 * @returns The Google OAuth URL.
 * @throws Error if the Google OAuth redirect URL or client ID is not set.
 */
export const getGoogleOAuthUrl = () => {
  const scopes = ["https://www.googleapis.com/auth/userinfo.profile", "https://www.googleapis.com/auth/userinfo.email"];

  if (GOOGLE_AUTH_REDIRECT_URL == null) {
    throw new Error("Google OAuth redirect URL not set");
  }

  if (GOOGLE_CLIENT_ID == null) {
    throw new Error("Google OAuth client ID not set");
  }

  const options = {
    redirect_uri: GOOGLE_AUTH_REDIRECT_URL,
    client_id: GOOGLE_CLIENT_ID,
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: scopes.join(" "),
  };
  const qs = new URLSearchParams(options).toString();

  return `${ROOT_URL}?${qs}`;
};

/**
 * Retrieves the JWT secret from the environment variables.
 * Throws an error if the `JWT_SECRET` is not set.
 *
 * NOTE: This should only be used in server-side code. Using this in client-side code can expose the secret.
 *
 * @returns The JWT secret.
 * @throws Error if `JWT_SECRET` is not set.
 */
export const getJWTSecret = () => {
  if (!isNode && getEnvironment() !== Environment.Production) {
    const message = "Attempting to validate JWT in browser environment. This can introduce security vulnerabilities by adding secret to build files";
    console.warn(message, "Consider using server-side validation instead");
  }

  const secret = process.env.JWT_SECRET;
  if (secret == null) {
    throw new Error("JWT_SECRET not set");
  }

  return secret;
};

/**
 * Checks if the provided token is a valid authentication token.
 * @param token - The authentication token to be validated.
 * @returns A promise that resolves to a boolean indicating whether the token is valid or not.
 *
 * @error If the token is invalid, the promise will reject with an error.
 */
export const isValidAuthToken = (token: string) => {
  const promise = new Promise<boolean>((resolve, reject) => {
    verify(token, getJWTSecret(), (err) => {
      if (err != null) {
        if (err.name === "TokenExpiredError") {
          resolve(false);
          return;
        }

        reject(err);
      }

      resolve(true);
    });
  });

  return promise;
};

/**
 * Decodes an authentication token and returns the user information.
 * @param token - The authentication token to decode.
 * @returns The decoded user information if the token is valid, otherwise null.
 */
export const decodeAuthToken = (token: string): User | null => {
  const payload = decode(token, { json: true }) as (Omit<User, "id"> & { _id: string }) | null;

  if (payload == null) {
    return null;
  }

  return {
    id: payload._id,
    role: payload.role,
    name: payload.name,
    imageUrl: payload.imageUrl,
  };
};

/**
 * Checks if the given user is an admin with role `OpsAdmin`, `SuperAdmin`, or `TechAdmin`.
 * @param user - The user to check.
 */
export function isAdmin(user: User): boolean;
/**
 * Checks if the given role is an admin role with `OpsAdmin`, `SuperAdmin`, or `TechAdmin`.
 * @param role - The role to check.
 */
export function isAdmin(role: UserRole): boolean;
/**
 * Checks if the given user or role is an admin.
 * @param userOrRole - The user or role to check.
 * @returns A boolean indicating whether the user or role is an admin.
 */
export function isAdmin(userOrRole: UserRole | User): boolean {
  const userRole = typeof userOrRole === "string" ? userOrRole : userOrRole.role;
  return [UserRole.OpsAdmin, UserRole.SuperAdmin, UserRole.TechAdmin].includes(userRole);
}

export function isTechAdmin(user: User): user is User & { role: UserRole.TechAdmin };
export function isTechAdmin(role: UserRole): role is UserRole.TechAdmin;
/**
 * Checks if the given user or role is a TechAdmin.
 * @param userOrRole - The user or role to check.
 * @returns A boolean indicating whether the user or role is a TechAdmin.
 */
export function isTechAdmin(userOrRole: User | UserRole): userOrRole is UserRole.TechAdmin {
  return typeof userOrRole === "string" ? userOrRole === UserRole.TechAdmin : userOrRole.role === UserRole.TechAdmin;
}

export function isOperationAdmin(user: User): user is User & { role: UserRole.OpsAdmin };
export function isOperationAdmin(role: UserRole): role is UserRole.OpsAdmin;
/**
 * Checks if the given user or role is an Operation Admin.
 * @param userOrRole - The user or role to check.
 * @returns A boolean indicating whether the user or role is an Operation Admin.
 */
export function isOperationAdmin(userOrRole: User | UserRole): userOrRole is UserRole.OpsAdmin {
  return typeof userOrRole === "string" ? userOrRole === UserRole.OpsAdmin : userOrRole.role === UserRole.OpsAdmin;
}

export function isSuperAdmin(user: User): user is User & { role: UserRole.SuperAdmin };
export function isSuperAdmin(role: UserRole): role is UserRole.SuperAdmin;
/**
 * Checks if the given user or role is a Super Admin.
 * @param userOrRole - The user or role to check.
 * @returns A boolean indicating whether the user or role is a Super Admin.
 */
export function isSuperAdmin(userOrRole: User | UserRole): userOrRole is UserRole.SuperAdmin {
  return typeof userOrRole === "string" ? userOrRole === UserRole.SuperAdmin : userOrRole.role === UserRole.SuperAdmin;
}
